import React from 'react'
import { Link } from 'react-router-dom'
import gold from '../generateGold'

const Front = () => {
    return (
        <div className='front'>
            <div className='title'>{gold('Asia Oriental Taste')}</div>
            <div className='description'>Una manera única de saborear Platos Orientales</div>
            <div className='wrapper'>
                <Link to='/menu'>
                    <div className='menus'>
                        <div className='name'>Menús</div>
                        <div className='arrow' />
                    </div>
                </Link>
            </div>
            <div className='fan2' />
        </div>
    )
}

export default Front